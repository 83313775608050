<template>
    <div class="page__wrapper">
        <div class="page__inner">
            <h1>{{ $t('dataDeletionPolicy.title') }}</h1>
            <div class="mb-40">
                <p class="mt-24">
                    {{ $t('dataDeletionPolicy.paragraph1') }}
                </p>
                <p class="mt-24">
                    {{ $t('dataDeletionPolicy.paragraph2') }}
                </p>
            </div>
            <GetInTouchSection
                class="get-in-touch-section"
                :title="$t('main.faqSection.cantFindAnswers')"
                :with-image="false"
            />
        </div>
    </div>
</template>

<script>
    import GetInTouchSection from '@/components/MainSections/GetInTouchSection';

    export default {
        name: 'DataDeletionPolicy',
        components: { GetInTouchSection },
    };
</script>

<style lang="scss" scoped>
    .page__wrapper {
        @include column-align-stretch;

        padding-top: 25px;
        padding-bottom: 25px;

        .page__inner {
            width: 100%;
        }
    }

    @include media($lg) {
        .page__wrapper {
            @include row-align-start;

            padding-top: 50px;
            padding-bottom: 0;

            .page__inner {
                max-width: 600px;

                margin: 0 auto;

                .get-in-touch-section {
                    @include column-align-center;
                    justify-content: stretch;

                    min-width: 100%;

                    margin-bottom: 40px;
                    margin-top: 20px;

                    :deep(.touch-section__inner) {
                        width: 100%;
                    }
                }
            }
        }
    }

    @include media($xl) {
        .page__wrapper {
            .page__inner {
                max-width: 800px;
            }
        }
    }
</style>
